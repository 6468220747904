import { path } from 'static-path';

export const Route = {
  signIn: '/sign-in',
  signOut: '/sign-out',
  dashboard: '/dashboard',
  forgotPassword: '/sign-in/forgot-password',
  resetPassword: '/sign-in/reset-password',
  mfaAuthChallenge: '/sign-in/challenge/mfa',
  newPasswordAuthChallenge: '/sign-in/challenge/new-password',
  messaging: '/dashboard/messaging',
  baaGettingStartedGuide: '/guides/baa',
  client: path('/dashboard/client/:clientID'),
  activeSAOD: '/dashboard/clients/active/saod',
  baaIntegration: '/dashboard/integrations/baa',
  activeBasic: '/dashboard/clients/active/basic',
  integrationsList: '/dashboard/integrations/list',
  integrations: '/dashboard/integrations',
  prospectsSAOD: '/dashboard/clients/prospects/saod',
  prospectsBasic: '/dashboard/clients/prospects/basic',
  subscribersSAOD: '/dashboard/clients/subscribers/saod',
  subscribersBasic: '/dashboard/clients/subscribers/basic',
  groupSAOD: path('/dashboard/clients/groups/:groupID/saod'),
  groupBasic: path('/dashboard/clients/groups/:groupID/basic'),
  onboardingSAOD: '/dashboard/clients/onboarding/saod',
  clientIncomeAndSpending: path('/dashboard/client/:clientID/income-and-spending'),
  clientCreateIncome: path('/dashboard/client/:clientID/income-and-spending/create'),
  clientEditIncome: path('/dashboard/client/:clientID/income-and-spending/edit/:incomeSourceID'),
  messagingCompose: '/dashboard/messaging/inbox/compose',
  messagingConversation: path('/dashboard/messaging/inbox/:conversationID'),
  messagingComposeAddAdditionalClients: '/dashboard/messaging/inbox/compose/add-additional-clients',
  messagingEmpty: '/dashboard/messaging/empty',
  messagingInbox: '/dashboard/messaging/inbox',
  messagingQuickCheckIn: '/dashboard/messaging/inbox/compose/quick-check-in',
  insurance: path('/dashboard/client/:clientId/insurance'),
  taxRate: path('/dashboard/client/:clientId/tax-rate'),
  taxRateEditTax: path('/dashboard/client/:clientId/tax-rate/:taxReturnID/edit'),
  editInsurance: path('/dashboard/client/:clientId/insurance/:insuranceID/edit'),
  onboardingBasic: '/dashboard/clients/onboarding/basic',
  suggestIntegration: '/dashboard/integrations/list/suggest',
  inviteToElements: path('/dashboard/client/:clientID/invite'),
  downloadConversationHistory: path('/dashboard/messaging/inbox/:conversationID/download'),
  baaIntegrationAccounts: '/dashboard/integrations/baa/accounts',
  baaIntegrationCreateAccounts: path('/dashboard/integrations/baa/accounts/create/:householdID'),
  baaIntegrationRequestAccess: '/dashboard/integrations/baa/request-access',
  clientResendInvitation: path('/dashboard/client/:clientID/resend-invitation'),
  clients: '/dashboard/clients',
  report: path('/dashboard/client/:clientId/report/:reportType'),
  clientTargetScores: path('/dashboard/client/:clientID/target-scores'),
  reportResource: path('/report/:firmClientID/:reportType/:reportHeightInPixels/:reportName'),
  accountSettings: '/dashboard/settings/account',
  enableMFA: '/dashboard/settings/account/mfa/enable',
  disableMFA: '/dashboard/settings/account/mfa/disable',
  addNetWorthItem: path('/dashboard/client/:clientID/add-net-worth-item'),
  downloadFirmMessageHistory: path('/dashboard/settings/firms/:id/download-message-history'),
  firmCreateTargetScoreRangeTemplate: path('/dashboard/settings/firms/:firmID/ranges/create'),
  firmTargetScoreRanges: path('/dashboard/settings/firms/:firmID/ranges'),
  firmEditTargetScoreRangeTemplate: path('/dashboard/settings/firms/:firmID/ranges/:templateID/edit'),
  qualifiedTerm: path('/dashboard/client/:clientId/qualified-term'),
  totalTerm: path('/dashboard/client/:clientId/total-term'),
  liquidTerm: path('/dashboard/client/:clientId/liquid-term'),
  debtRate: path('/dashboard/client/:clientId/debt-rate'),
  savingsRate: path('/dashboard/client/:clientId/savings-rate'),
  realEstateTerm: path('/dashboard/client/:clientId/real-estate-term'),
  businessTerm: path('/dashboard/client/:clientId/business-term'),
  equityRate: path('/dashboard/client/:clientId/equity-rate'),
  burnRate: path('/dashboard/client/:clientId/burn-rate'),
};

export const ResourceRoute = {
  setMixPanelUser: '/resources/setMixPanelUser',
  muteConversation: '/resources/muteConversation',
  createMessage: '/resources/createMessage',
  assetLoansForLoan: path('/resources/assetLoansForLoan/:householdID/:loanID'),
  assetLoansForAsset: path('/resources/assetLoansForAsset/:householdID/:assetID'),
  persistChartRangeFilter: '/resources/persistChartRangeFilter',
  netWorthGraphData: path('/resources/netWorthGraphData/:householdID/:dateStart/:dateEnd'),
  graphDataPoints: path('/resources/graphDataPoints/:tenantID/:groupID/:dateStart/:dateEnd'),
  saodPersistenceConfig: path('/resources/saodPersistenceConfig/:clientListViewFilter'),
  persistedExperienceProspectDiscoveryModalDismissExpiryISODate: path(
    '/resources/persistedExperienceProspectDiscoveryModalDismissExpiryISODate/:userID'
  ),
  persistedIsExperienceProspectDiscoveryTriggerActive: path(
    '/resources/persistedIsExperienceProspectDiscoveryTriggerActive/:userID'
  ),
  persistedIsExperienceWelcomeModalTriggerActive: path(
    '/resources/persistedIsExperienceWelcomeModalTriggerActive/:userID'
  ),
  createAuthSession: '/resources/createAuthSession',
};

export enum RouteID {
  clients = 'routes/dashboard/clients',
  client = 'routes/dashboard/client.$id',
  taxes = 'routes/dashboard/client.$id/$householdID/taxes',
  addNetWorthItem = 'routes/dashboard/client.$id/add-net-worth-item',
  insurance = 'routes/dashboard/client.$id/$householdID/insurance',
  createOnePagePlan = 'routes/dashboard/one-page-plan.$clientId.$householdId/create',
  addNetWorthItemDebt = 'routes/dashboard/client.$id/add-net-worth-item/debt.$type',
  accountDetails = 'routes/dashboard/client.$id/$householdID/accounts.$accountID.$accountType',
  editAccountDetails = 'routes/dashboard/client.$id/$householdID/accounts.$accountID.$accountType/edit',
  addNetWorthItemRealEstate = 'routes/dashboard/client.$id/add-net-worth-item/real-estate.$type',
  integrations = 'routes/dashboard/integrations',
}

export enum ThirdPartyLink {
  morningstarAccountView = 'https://www.byallaccounts.net/BAAWebApp/BAALogin.html',
  zapier = 'https://zapier.com/apps/elements-financial-monitoring/integrations',
  pdfGeneratorFallbackProcess = 'https://ap38xcmgyb.us-west-2.awsapprunner.com/process',
}
